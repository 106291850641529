import React from 'react';
import PropTypes from 'prop-types';
import { FacebookShareButton, FacebookIcon } from 'react-share';
import Grid from '@material-ui/core/Grid';
import Email from '@material-ui/icons/Email';
import Reply from '@material-ui/icons/Reply';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';

import Link from '../link/Link';

const HeroArticle = ({ title, date, postType, location }) => (
  <React.Fragment>
    <Typography variant="h6" component="h5" align="center" gutterBottom>
      {date}
    </Typography>
    <Typography variant="h3" component="h1" align="center" gutterBottom>
      {title}
    </Typography>
    <Grid
      container
      item
      direction="row"
      justify="center"
      alignItems="center"
      spacing={3}
    >
      <Grid item>
        <FacebookShareButton quote={title} url={location.href}>
          <FacebookIcon size={50} round />
        </FacebookShareButton>
      </Grid>
      <Grid item>
        <Link to="/contacts">
          <IconButton style={{ backgroundColor: '#FFA07A' }}>
            <Email style={{ color: '#FFF' }} />
          </IconButton>
        </Link>
      </Grid>
      <Grid item>
        <Link to={`/${postType.toLowerCase()}`}>
          <IconButton style={{ backgroundColor: 'orange' }}>
            <Reply style={{ color: '#FFF' }} />
          </IconButton>
        </Link>
      </Grid>
    </Grid>
  </React.Fragment>
);

HeroArticle.propTypes = {
  date: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  postType: PropTypes.string.isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired
  }).isRequired
};

export default HeroArticle;
