/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import parse, { domToReact } from 'html-react-parser';

import makeStyles from '@material-ui/core/styles/makeStyles';
import Box from '@material-ui/core/Box';

import Link from '../link/Link';

const useStyles = makeStyles(theme => ({
  content: {
    '& > h1': {
      ...theme.typography.h1
    },
    '& > h2': {
      ...theme.typography.h2
    },
    '& > h3': {
      ...theme.typography.h3
    },
    '& > h4': {
      ...theme.typography.h4
    },
    '& > h5': {
      ...theme.typography.h5
    },
    '& > h6': {
      ...theme.typography.h6
    },
    '& > p': {
      ...theme.typography.subtitle1
    },
    '& > ol, ul': {
      ...theme.typography.subtitle1
    },
    '& img': {
      maxWidth: '100%',
      display: 'flex',
      margin: '0 auto'
    }
  }
}));

const parserOptions = {
  replace: ({ name, attribs, children }) => {
    switch (name) {
      case 'a':
        return (
          <Link {...attribs} to={attribs.href}>
            {domToReact(children)}
          </Link>
        );
      default:
        return null;
    }
  }
};

const RichText = ({ html }) => {
  const classes = useStyles();
  return (
    <Box my={2} maxWidth="100%" className={classes.content}>
      {parse(html, parserOptions)}
    </Box>
  );
};

RichText.propTypes = {
  html: PropTypes.string.isRequired
};

export default RichText;
