import React from 'react';
import PropTypes from 'prop-types';
import { navigate, graphql } from 'gatsby';

import Container from '../layout/Container';
import Page from '../layout/Page';

import HeroArticle from '../components/hero/HeroArticle';
import RichText from '../components/rich-text';
import InformationSection from '../components/product/information-section/InformationSection';
import SectionHeading from '../components/section/SectionHeading';

const Article = ({ data, location }) => {
  const { prismicPost } = data;
  if (!prismicPost) {
    if (typeof window !== 'undefined') {
      navigate('/');
    }
    console.error('Page has not been translated.');
    return null;
  }

  const { data: prismicData } = prismicPost;
  const {
    page_title,
    page_description,
    page_keywords,
    page_image,
    title,
    post_type,
    date,
    content,
    files_title,
    files
  } = prismicData;

  return (
    <Page
      seo={{
        page_title,
        page_description,
        page_keywords,
        page_image,
        pathname: location.pathname
      }}
    >
      <Container isCenter size={50}>
        <HeroArticle
          title={title}
          date={date}
          postType={post_type}
          location={location}
        />
      </Container>

      <Container>
        <RichText html={content.html} />
      </Container>

      {files_title && (
        <Container>
          <SectionHeading title={files_title} />
        </Container>
      )}

      {files && files.length > 0 && (
        <Container>
          <InformationSection leaflets={files} />
        </Container>
      )}
    </Page>
  );
};

Article.propTypes = {
  data: PropTypes.shape({
    prismicPost: PropTypes.shape({
      data: PropTypes.shape().isRequired
    }).isRequired
  }).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired
  }).isRequired
};

export const query = graphql`
  query($locale: String!, $slug: String!) {
    prismicPost(lang: { eq: $locale }, uid: { eq: $slug }) {
      uid
      data {
        page_title
        page_description
        page_keywords
        page_image {
          alt
          copyright
          url
        }
        title
        post_type
        date(formatString: "MMMM Do, YYYY")
        content {
          html
          text
        }
        files_title
        files {
          file_title
          file {
            url
          }
        }
      }
    }
  }
`;

export default Article;
